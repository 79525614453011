.about-section {
  background-color: var(--color-background-black-main);
  padding: 70px 0 56px;

  @media (max-width: 767px) {
    padding: 40px 10px;
  }

  .about-item {
    position: relative;
    margin-bottom: 15px;
    color: var(--color-text-white);

    @media (max-width: 767px) {
      margin-bottom: 40px;
      padding-left: 15px;
    }


    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-transform: uppercase;
      margin-bottom: 10px;
      color: var(--color-text-cream);
      position: relative;

      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      span {
        display: block;
      }

      &:before {
        position: absolute;
        content: '';
        left: -15px;
        top: 0;
        width: 4px;
        height: 100%;
        border-radius: 1px;
        background-color: var(--color-background-yellow);
      }
    }

    &__text {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: var(--color-text-white)
    }
  }
}