.mobile-connect-wallet-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(72px);
  transition: all 300ms ease-in;



  &__button.active {
    background-color: var(--color-background-black-main);
    box-shadow: 5px 6px 25px rgba(2, 9, 72, 0.08);
    color: var(--color-text-white);
  }

  &__button {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    cursor: pointer;
    padding: 20px 16px;
    background-color: var(--color-background-yellow);
    transition: 0.15s linear;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1E1E24;
    border-radius: 12px 12px 0 0;
  }

  &__button:not(.active):hover {
    background-color: var(--color-background-yellow-hover);
  }

  &__button svg {
    height: 20px;
    margin-left: 5px;
  }

  &__show {
    transform: translateY(0px);
  }
}