:root {
  --color-text-white: #FFFFFF;
  --color-text-black: #2E2B2C;
  --color-text-white-transparantize: rgba(255, 255, 255, 0.5);
  --color-background: #121222;
  --color-background-blue: #1E1E2F;
  --color-background-black-light: #33334A;
  --color-background-black-medium: #25253C;
  --color-background-black-main: #1E1E2F;
  --color-background-grey: #F8F8F8;
  --color-background-yellow: #FFCC7E;
  --color-background-yellow-hover: #e7b569;
  --color-text-red: #FE4646;
  --color-text-yellow: #FFCC7E;
  --color-text-grey: #A9AAB7;
  --color-text-cream: #FFEACB;
  --color-text-success: #02C88D;
  --color-text-violet: #C58BFF;
  --color-main-gradient-text: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);
  --main-shadow: 4px 2px 30px #101025;

  --color-separator: #414347;
  --color-separator-transparentize: rgba(65, 67, 71, 0.5);
  --container-max-width: 100%;
}

@media (min-width: 576px) {
  :root {
    --container-max-width: 540px;
  }
}

@media (min-width: 768px) {
  :root {
    --container-max-width: 720px;
  }
}

@media (min-width: 992px) {
  :root {
    --container-max-width: 960px;
  }
}

@media (min-width: 1200px) {
  :root {
    --container-max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  :root {
    --container-max-width: 1320px;
  }
}

:root {
  --container-sticky-max-width: calc(var(--container-max-width) + ((100vw - var(--container-max-width)) / 2) - 12px)
}
