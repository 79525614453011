.launch-form {
  text-align: left;
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 30px 0 20px;
    color: #FFFFFF;
  }

  .round-button {
    background: var(--color-background-yellow);
    border-radius: 16px;
    width: 100%;
    height: 68px;
  }

  .form-label, .form-check-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .project-type-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    background: var(--color-background-black-medium);
    border-radius: 16px;
    padding: 4px;

    input {
      display: none;
    }

    .form-check {
      display: inline-flex;
      width: 100%;
      height: 48px;
      justify-content: center;
      margin-right: 0;
    }

    label {
      font-weight: 500;
      width: 100%;
      justify-content: center;
      font-size: 16px;
      height: 48px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      border-radius: 12px;
      width: 100%;
    }

    input:checked + label {
      background: var(--color-background-black-light);
      color: var(--color-text-yellow);
      font-weight: 700;
    }
  }

  .step-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      color:var(--color-text-cream);
      margin: 25px 0 10px;
    }

    &__description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #FFFFFF;
      margin-bottom: 20px;
    }

  }
}