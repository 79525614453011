.tile.market-item {
  --card-border: 1px;
  position: relative;
  display: block;
  padding: 0;
  background-color: #F8F8F8;
  transition: all 300ms ease-in;
  background-clip: padding-box;
  backdrop-filter: none;

  &:hover {
    background-color: rgba(232, 232, 232, 0.84);
  }

  a {
    text-decoration: none;
  }

 img {
    max-width: 100%;
  }
}

.market-item {
  &__body {
    padding: 0 15px 15px;
  }

  &__project {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.36px;
    color: var(--color-text-black);

    img {
      width: 16px;
      height: 16px;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-black);
    line-height: 112%;
  }

  footer {
    color: var(--color-text-black);
  }

  &__bid {
    font-size: 12px;
    line-height: 150%;
    color: var(--color-text-black);
    letter-spacing: 0.36px;

    > span {
      color: var(--color-text-black);
      font-weight: 500;
    }
  }
}