.calendar-month {
  position: relative;

  @media (max-width: 1200px) {
    padding-top: 75px;
  }

  @media (max-width: 767px) {
    padding-top: 10px;
  }

  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 112%;
    text-transform: uppercase;
    color: var(--color-text-white);
    position: absolute;
    left: 0;
    top: 20px;
    @media (max-width: 767px) {
      top: 17px;
    }
  }

  &:after {
    content: '';
    margin: 14px 0;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    background-color: #8E8F96;
    display: block;
  }

  &:last-child:after {
    display: none;
  }
}
