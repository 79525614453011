.collection-card.tile {
  --card-border: 1px;
  padding: 0;
  border: none;
  position: relative;
  display: block;
  max-width: 386px;
  background-color: var(--color-background-blue);
  box-shadow: var(--main-shadow);
  background-clip: padding-box;
  transition: all 300ms ease-in;
  color: inherit;
  border-radius: 24px;
  overflow: hidden;
  backdrop-filter: none;
  text-decoration: none;

  @media (max-width: 560px) {
    max-width: 300px;
  }
}

.collection-card {
  @media (max-width: 767px) {
    max-width: 100%;
    //margin: auto;
    width: 320px;
  }

  &__img {
    min-height: 300px;
    display: flex;
    align-items: center;
    background: var(--color-background-black-medium);
    justify-content: center;

    img {
      font-size: 0;
      max-width: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: var(--color-text-white);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: var(--color-text-grey);
  }

  &__body {
    padding: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__price {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: var(--color-text-yellow);
    text-transform: uppercase;
  }

  &.tile:hover {
    background-color: var(--color-background-black-medium);
  }
}
