.our-history {
  background-color: #F8F8F8;
  padding: 180px 0 120px;
  background-image: url('../../../assets/Landing/story-image.png');
  background-position: 18% top;
  background-repeat: no-repeat;
  background-size: 34%;


  .title {
    font-weight: 600;
    font-size: 80px;
    line-height: 112%;
  }

  &__decription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin-top: 25px;
    color: var(--color-text-black);
  }
}