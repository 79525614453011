.ntf-auction-stats__progress {
    margin-bottom: 20px;
}

.ntf-auction-stats__progress .progress {
    background: rgba(255, 255, 255, 0.2);
    overflow: unset;
    backdrop-filter: blur(20px);
    border-radius: 10px;
    overflow: hidden;
}

.ntf-auction-stats__progress .progress-bar {
    background: linear-gradient(108.68deg, #C4A2FF 4.9%, #FF9393 74.14%);
    box-shadow: 0px 0px 20px rgba(61, 84, 194, 0.5);
    border-radius: 10px;
}

.ntf-auction-stats__status {
    font-weight: 600;
    font-size: 40px;
    line-height: 126%;
    color: var(--color-text-black);
}

.ntf-auction-stats__last-price span {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;
}

.ntf-auction-stats__last-price {
    text-align: right;
}

@media (max-width: 767px) {
    .ntf-auction-stats__last-price {
        text-align: left;
        margin: 15px 0 0;
        padding-bottom: 15px;
    }
}