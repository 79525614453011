.get-ready-badge.tile--with-shadow {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 32px;
}

.get-ready-badge .tile__main {
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 40px;
    text-align: center;
}
