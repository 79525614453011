.timeline {
    position: relative;
    padding: 30px 0;
}

.timeline:before {
    left: 50%;
    top: 0;
    position: absolute;
    content: "";
    transform: translateX(-50%);
    width: 180px;
    height: 100%;
    border-radius: 32px;
    background-image: url('../../../assets/timeline/timeline-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
}

@media (max-width: 1200px) {
    .timeline:before {
        display: none;
    }
}