.whitelist-modal {

  .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 30px;
    }
  }

  .modal-dialog {
    width: 608px;
    max-width: 100%;
    background: var(--color-background-black-main);
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    @media (max-width: 767px) {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  .modal-content {
    background: var(--color-background-black-main);
  }

  &__close {
    position: absolute;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    z-index: 2;
    background-color: transparent;
    border: none;
    padding: 0;

    svg, span {
      max-width: 100%;
    }

    svg path {
      fill: #8E8F96
    }
  }

  .whitelist-form {
    max-width: none;
    width: 100%;
  }
}

.stay-connected {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #8E8F96;
  }

  .social-links {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 0;
    margin: 0;
    margin-top: 20px;
    list-style: none;

    a {
      border: 1px solid rgba(142, 143, 150, 0.5);
      backdrop-filter: blur(40px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      width: 56px;
      height: 56px;

      svg {
        width: 27px;
        height: 27px;
      }

      svg path {
        fill: var(--color-text-cream);
        transition: all 300ms ease-in;
      }

      &:hover svg path {
        fill: var(--color-background-yellow)
      }
    }
  }
}