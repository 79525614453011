.launch-project-section {
  background-image: url('../../../assets/launch-project-bg.png');
  background-size: cover;
  background-position: center center;
  padding: 180px 0 100px;
  min-height: 600px;

  @media (max-width: 767px) {
    padding: 100px 0 50px;
    min-height: 400px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }

  &__title {
    font-weight: 600;
    font-size: 64px;
    line-height: 112%;
    max-width: 760px;
    margin: 0 auto;

    text-align: center;

    color: var(--color-text-yellow);

    @media (max-width: 767px) {
      font-size: 42px;
    }
  }

  .round-button.large {
    align-items: center;
    gap: 10px;
    display: flex;

    svg path {
      fill: var(--color-text-black)
    }
  }
}