.nft-token-page {
    position: relative;
    padding-top: 130px;
    padding-bottom: 100px;
}

.nft-token-page:before {
    position: fixed;
    content: '';
    width: 50%;
    height: calc(100% + 81px);
    top: -81px;
    left: 0;
    z-index: -1;
}

.nft-token-section {
    margin-top: 44px;
}

.nft-token-section .loader:first-of-type {
    margin: auto;
}

.nft-token-asset-box {
    display: flex;
    width: 100%;
}

.nft-token-asset {
    width: 100%;
    min-height: 400px;
    border-radius: 28px;
}

.nft-token__heading {
    display: flex;
    flex-direction: column;
}

.nft-token__description {
    margin-top: 32px;
    margin-bottom: 32px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: var(--color-text-white);
}

.nft-token__social-share {
    padding-top: 40px;
    margin-top: 40px;
    margin-bottom: 40px;

}
@media (max-width: 767px) {
    .nft-token__social-share {
        padding-top: 10px;
        margin: 10px 0 ;
    }
}

.nft-token__description-list ~ p {
    margin-top: 28px;
    margin-bottom: 0;
}

.nft-token-page .tile {
    border-radius: 32px;
}

.nft-token-page .tile + .tile {
    margin-top: 18px;
}

.nft-token__collection {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.nft-token__collection-logo {
    width: 60px;
    height: 60px;
}

.nft-token__collection-logo img {
    max-width: 100%;
}

.nft-token__collection-name {
    margin-left: 15px;
    display: flex;
    align-items: center;
}

.nft-token__heading-text {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-transform: uppercase;
    color: var(--color-text-black);
}

.nft-token__amount-stat {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--color-text-yellow);

}

.nft-token__amount-stat span {
    color: #8E8F96;
}

.nft-token__collection-name a {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-text-grey);
}

.nft-token__badges {
    display: flex;
    align-items: center;
    gap: 5px;
}

.nft-token__badge {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    padding: 5px 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
    display: flex;
    letter-spacing: 1px;
}

@media (max-width: 767px) {
    .nft-token-page .nft-token-asset-box {
        margin: -25px 0 -50px;
    }

    .nft-token-page .nft-token__heading {
        margin-top: 32px;
    }
}