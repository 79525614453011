.collection-details-page {
    padding: 100px 0 100px;

    @media (max-width: 400px) {
        padding-top: 100px;
    }

    & > .loader {
        margin: 0 auto;
    }

    .whitelist-block {
        margin-top: 40px;
    }

    .whitelist-block p {
        max-width: 370px;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 1px;
    }

    .whitelist-block a:not(.round-button) {
        color: inherit;
    }

    .whitelist-block a:not(.round-button):hover {
        color: var(--color-text-white);
    }

    .whitelist-badge {
        display: inline-flex;
        padding: 24px;
        align-items: center;
        border: 1px solid var(--color-text-black);
        border-radius: 26px;
        margin-bottom: 32px;
    }

    .whitelist-badge.invalid,
    .whitelist-badge.rejected,
    .whitelist-badge.not_submitted {
        border: none;
        background-color: rgba(254, 70, 70, 0.1);
    }

    .whitelist-badge.in_review {
        border-color: rgba(255, 212, 70, 0.32);
        background: rgba(255, 212, 70, 0.1);
    }

    .whitelist-badge .loader {
        --loader-size: 32px;
    }

    .whitelist-badge img {
        margin-right: 16px;
    }
}

.collection__head {
    gap: 12px;

    .collection__head-content {
        flex-wrap: wrap;
        gap: 5px
    }
}

.collection__icon {
    width: 40px;
    height: 40px;
    background-color: var(--color-background-black);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 100%;
        height: 100%;
    }
}


.collection {
    &__head-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    &__name {
        font-weight: 600;
        font-size: 40px;
        line-height: 126%;
        margin-bottom: 0;
    }

    &__collection {
        font-weight: 600;
        font-size: 24px;
        line-height: 126%;
    }

    &__body {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.5px;
    }

    &__image {
        max-width: 100%;

        img {

            max-width: 100%;
        }
    }
}


@media (max-width: 767px) {
    .collection__name {
        font-size: 36px;
    }

    .collection__collection {
        font-size: 16px;
    }
}

