.my-collection-page {
    padding-top: 100px;
}

.nfts-page__top-banner {
    height: calc(100vh - 260px);
    min-height: 670px;
    overflow: hidden;
    padding: 60px 0;
}

.nfts-page__top-banner .container {
    height: 100%;
}

.nfts-page__top-banner p, .nfts-page__top-banner h2.title {
    color: var(--color-text-white)
}

.nfts-page__top-banner .title {
    font-weight: 600;
    font-size: 64px;
    line-height: 112%;
    margin-top: 50px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.nfts-page__top-banner .subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media (max-width: 768px) {
    .nfts-page__top-banner .title {
        font-size: 42px;
    }
    .nfts-page__top-banner .subtitle {
        font-size: 16px;
    }
}

.nfts-page__top-banner .background {
    z-index: -1;
    background-color: var(--color-background-black-main);
}

.nfts-page .hero-row {
    align-content: center;
    padding-bottom: 0;
    height: 100%;
    z-index: 100;
}

.nfts-page .hero-row__logo {
    max-width: 200px;
    margin: 0 auto;
}
.nfts-page .hero-row__logo img {
    max-width: 100%
}

.nfts-page .hero-row .hero-row__main {
    margin: 0 auto min(11vw, 12vh);
    max-width: 700px;
}

.hero-row__main p {
    position: relative;
    z-index: 1;
}

.nfts-page .hero-row__main:before {
    position: absolute;
    content: '';
    width: 120%;
    height: 140%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: blur(60px);
    z-index: -1;
    background: linear-gradient(360deg, #0F0F1E 0%, rgba(6, 1, 1, 0.8) 102.06%);
}

@media (max-width: 767px) {
    .nfts-page .hero-row__main:before {
        display: none;
    }
    .nfts-page__top-banner .background:after {
        background: linear-gradient(360deg, #0F0F1E 0%, rgba(6, 1, 1, 0.8) 102.06%);
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
    }
}