.event-card {
  position: relative;
  margin: 8px 0;
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding-top: 50px;
    margin: 20px 0;
  }

  &__day {
    position: absolute;
    font-weight: 600;
    font-size: 64px;
    line-height: 112%;
    top: 0;
    right: calc(100% + 32px);
    text-align: right;
    text-transform: uppercase;
    color: var(--color-text-cream);


    @media (max-width: 767px) {
      right: 10px;
      top: -20px;
      font-size: 40px;
    }
  }

  &__image {
    max-width: 45%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 24px;

    img {
      max-width: 100%;
    }

    @media (max-width: 991px) {
      max-width: 30%;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__social {
    margin-top: 10px;
    opacity: 0;
    transition: all 300ms ease-in;

    @media (max-width: 767px) {
      opacity: 1;
    }

    svg path {
      fill: var(--color-background-yellow)
    }
  }

  &__body {
    position: relative;
    padding: 40px;
    transition: background-color 300ms ease-in;
    border-radius: 0 4px 4px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;


    @media (max-width: 991px) {
      max-width: 70%;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      padding: 15px;
    }
  }

  &__title a {
    font-weight: 600;
    font-size: 40px;
    line-height: 112%;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--color-text-cream);


    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  &__link {
    position: absolute;
    opacity: 0;
    display: inline-flex;
    right: 24px;
    bottom: 24px;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    padding: 24px;
    transition: all 300ms ease-in;
    background: #FFCC7E;
    border-radius: 16px;
    @media (max-width: 991px) {
      opacity: 1;
    }

    @media (max-width: 767px) {
      width: 44px;
      height: 44px;
    }

    svg {
      width: 27px;
      height: 19px;

      path {
        fill: var(--color-background-black-main)
      }
    }

    &:hover {
      transform: translateX(2px);
    }
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-white);
    margin-top: 10px;
  }

  &:hover {

    .event-card__body {

      border-radius: 0 24px 24px 0;
      overflow: hidden;
    }

    .event-card__link {
      opacity: 1;
    }

    .event-card__body {
      background: var(--color-background-black-medium);
    }


    .event-card__social {
      opacity: 1;
    }
  }

  &__artist {
    position: relative;

    &:before {
      content: '';
      display: block;
      margin: 8px 0;
      height: 1px;
      width: 100%;
      opacity: 0.5;
      background-color: #8E8F96;
    }
  }
}

.artist-component {
  &__label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: var(--color-text-grey);
    margin-bottom: 10px;
  }

  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2E2B2C;

    img {
      max-width: 100%;
    }
  }

  &__name {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-text-grey);

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}