.art-card {
  width: 127%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 32px;
  overflow: hidden;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    overflow: visible;
  }
  @media (max-width: 563px) {
    overflow: hidden;
    justify-content: flex-start;
  }

  &__banner {
    position: relative;
    max-width: 55%;
    width: 100%;
    border-radius: 14px;
    overflow: hidden;

    @media (max-width: 1200px) {
      max-width: 100%;
    }
  }

  .collection-card__img {
    min-height: 80%;
    border-radius: 16px;
    overflow: hidden;

    img {
      height: 435px;
      max-width: none;
    }
  }

  &__background {
    margin: 0;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,7));
      position: absolute;
    }

    img {
      transform: scale(1.015);
      width: 100%;
      transition: all 300ms;
      max-height: 558px;
    }

    @media (max-width: 991px) {
      img {
        max-width: 100%;
      }
    }

  }
  &:hover .art-card__background img {
    transform: scale(1);
  }

  &__logo {
    position: absolute;
    left: 40px;
    top: 40px;

    @media (max-width: 563px) {
      display: none;
    }
  }

  &__body {
    left: 40px;
    bottom: 40px;
    position: absolute;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 112%;
    color: var(--color-text-cream);
    max-width: 500px;

    @media (max-width: 767px) {
      font-size: 32px;
    }
  }

  &__info {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-right: 15px;
    max-width: 530px;
    color: #FFFFFF;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
      max-height: 75px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__slider {
    display: flex;
    position: relative;
    width: 1000px;
    max-width: 43%;
    gap: 8px;

    .nft-card {
      width: 80%;
      margin-right: 15px;

      @media (max-width: 563px) {
        flex-shrink: 0;
      }
    }

    @media (max-width: 1200px) {
      max-width: 100%;
    }

    @media (max-width: 563px) {
      max-width: none;
    }
  }

  &__over-layer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(89.21deg, rgba(30, 30, 47, 0) 0.68%, rgba(30, 30, 47, 0.9) 57.89%, #1E1E2F 99.32%);

    .round-button.bordered {
      color: var(--color-text-cream);

      svg path {
        fill: var(--color-text-cream)
      }
    }

    @media (max-width: 1400px) {
      justify-content: start;
      padding-left: 40px;
    }

    @media (max-width: 1200px) {
      justify-content: center;
      padding-left: 0px;
    }

    @media (max-width: 600px) {
      justify-content: flex-start;
      padding-left: 70px;
    }

    .round-button.large {
      gap: 5px;
      display: flex;
      transition: all 300ms;
      width: 186px;
      line-height: inherit;
      height: 68px;
      background: var(--color-background-blue);
      border-radius: 16px;
      padding-left: 0;
      padding-right: 0;
      justify-content: center;

      svg path {
        transition: all 300ms;
      }

      &:hover {
        transform: translateX(2px);

        svg path {
          fill: var(--color-background-yellow);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .round-button.bordered.dark {
      width: 343px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 68px;
      background: var(--color-background-black-medium);
      border: 1px solid var(--color-text-cream);
      border-radius: 16px;

      svg path {
        fill: var(--color-text-cream)
      }
    }
  }

  .collection-card.tile {
    height: 547px;

    .collection-card__img img {
      height: 435px;
    }
  }
}