.bid-form-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 300px;
}

.bid-form__buttons {
    margin-top: auto;
}

@media (max-width: 767px) {
    .bid-form-wrapper {
        border-top: 1px solid var(--color-separator);
        min-height: 350px;
    }
}
