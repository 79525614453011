.aside-section {
  &__title {
    max-width: 286px;
    height: 58px;
    background: rgba(35, 40, 47, 0.4);
    backdrop-filter: blur(80px);
    border-radius: 16px;
    padding: 16px;
    color: var(--color-text-white);
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
    margin: 24px 0;
  }

  &__buttons {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__button {
    border: none;
    background: none;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 1px;
    color: var(--color-text-black);
    transition: color 300ms ease-in;

    &:hover {
      color: var(--color-text-yellow)
    }

    &--active {
      color: var(--color-text-yellow)
    }
  }
}