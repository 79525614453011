.calendar-page.page {
  padding: 0px 0;
  
  .page-top-banner {
    background-image: url('../../assets/upcoming-drops-bg.png');
    padding-top: 80px;
    margin-bottom: 80px;
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      background-size: contain;
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 112%;
      }
    }
  }
}