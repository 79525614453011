.team {
    padding-bottom: 90px;
}

.team__list {
    margin-top: 24px;
    margin-bottom: 48px;
}

@media (max-width: 767px) {
    .team {
        padding-bottom: 40px;
    }
}