.social-links {
    /*margin-top: 24px;*/
    display: flex;
    align-items: center;
}

@media (max-width: 575px) {
    .social-links {
        justify-content: center;
    }
}

@supports (gap: 12px) {
    .social-links {
        gap: 12px;
    }
}

@supports not (gap: 12px) {
    .social-links > * + * {
        margin-left: 12px;
    }
}

.social-links__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background: transparent;
    backdrop-filter: blur(40px);
    border-radius: 50%;
    transition: .2s linear;
}

.social-links__link svg {
    width: 32px;
    height: 32px;
}

.social-links__link svg path {
    fill: var(--color-text-cream)
}

.social-links__link:hover {
    background: rgba(255, 255, 255, 0.35);
}
