header {
    padding: 15px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    transition: all 300ms;

    @media (max-width: 991px) {
        padding: 0 15px;
    }
}
header.header-fixed-bg {
    background-color: var(--color-background);
}

.header__navbar-menu.navbar {
    justify-content: space-between;
    display: flex;
}

.navbar-brand img {
    height: 60px;
}
.navbar-expand-lg .navbar-collapse {
    flex-grow: inherit;
}

.nav-link {
    position: relative;
    font-family: 'Atrament', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-text-white);
}

header .nav-link {
    padding: 8px 8px;
}

.nav-link:hover {
    color: var(--color-text-yellow) !important;
}

.nav-link.active {
    border: none;
}

.nav-link.active:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    top: 100%;
    left: 0;
    background-color: var(--color-background-yellow);
    border-radius: 2px;
}

nav .btn {
    background: var(--color-text-white);
    border-radius: 50px;
    line-height: 38px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.nav-dropdown .round-button {
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent !important;
    letter-spacing: 0.005em;
}

@supports (gap: 24px) {
    .navbar-collapse {
        gap: 24px;
    }
}

@supports not (gap: 24px) {
    .navbar-collapse > * + * {
        margin-left: 24px;
    }
}

@media (max-width: 1199px) {
    .navbar .nav-link {
        padding: 0.5rem;
    }
}

.nav-dropdown__ico {
    width: 32px;
    height: 32px;
    margin-right: 15px;
    background: #2E2B2C;
    border-radius: 50%;
    padding: 3px;
}
.nav-dropdown__ico img {
    max-width: 100%;
}

.nav-dropdown__menu.dropdown-menu {
    background-color: var(--color-background-blue);
    min-width: 184px;
    top: calc(100% + 15px);
    z-index: 2;
    padding: 0 15px;
    box-shadow: 4px 2px 30px rgba(0, 0, 0, 0.32);
    border-radius: 12px;
}

.nav-dropdown__item.dropdown-item {
    color: var(--color-text-white);
    padding: 15px 0;
    font-weight: 500;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--color-separator-transparentize);
}

.nav-dropdown__item.dropdown-item:last-child {
    border-bottom: none;
}

.nav-dropdown__item.dropdown-item:hover,
.nav-dropdown__item.dropdown-item.active,
.nav-dropdown__item.dropdown-item:active{
    background-color: inherit;
    color: var(--color-text-yellow);
}

.nav-dropdown.nav-dropdown--active {
    background: var(--color-background-black-main);
    border-radius: 8px;
    .round-button {
        color: var(--color-text-yellow)
    }
}

.header__web3-actions {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
}

.header__connect-wallet.active {
    background-color: var(--color-background-black-main);
    box-shadow: 5px 6px 25px rgba(2, 9, 72, 0.08);
    color: var(--color-text-white);
    border-radius: 12px;
}

.header__connect-wallet {
    display: flex;
    align-items: center;
    margin-left: 8px;
    text-transform: uppercase;
    cursor: pointer;
    padding: 8px 16px;
    background-color: var(--color-background-yellow);
    transition: 0.15s linear;
    border-radius: 12px;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #1E1E24;
}

.header__connect-wallet:not(.active):hover {
    background-color: var(--color-background-yellow-hover);
}

.header__connect-wallet svg {
    height: 20px;
    margin-left: 5px;
}


@media (max-width: 991px) {
    .navbar-brand img {
        max-width: 160px;
        height: auto;
    }

    header {
        z-index: 11;
    }

    .navbar-toggler, .navbar-light .navbar-toggler {
        color: transparent;
        border: none;
    }

    header .navbar-toggler.collapsed .navbar-toggler-icon {
        background-image: url('../../../assets/menu.svg');
    }

    header .navbar-toggler .navbar-toggler-icon {
        background-image: url('../../../assets/menu-opened.svg');
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: calc(100% + 30px);
        background-color: var(--color-background-black-main);
        top: 0;
        padding: 106px 0 40px;
        z-index: -1;
        margin: 0 -16px;
        height: 100vh;
    }
}

.nav-dropdown.apps-dropdown  {
    .dropdown-toggle::after {
        display: none;
    }

    .round-button {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: var(--color-background-blue) !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-left: 0;
        }

        svg * {
            fill: var(--color-text-cream)
        }

        &:hover svg * {
            fill: var(--color-background-yellow)
        }
    }

    &.show {
        .round-button svg * {
            fill: var(--color-background-yellow)
        }
    }

    .dropdown-menu.show {
        right: 0;
        padding: 24px;
        border-radius: 16px;

        @media (max-width: 767px) {
            right: auto;
            left: -50px;
            max-width: 300px;
            .apps-item__description {
                white-space: break-spaces;
            }
        }
    }

    .apps-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 18px;

        &__body {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }


        &__name {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: var(--color-text-cream);
        }

        &__description {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #FFFFFF;
        }
    }

    .apps-dropdown__title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 10px;
    }
}

.nav-dropdown .round-button.right-actions-wrapper__launch-link {
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
    width: auto;
    padding: 14px 18px;
    display: flex;
    gap: 10px;
    border-radius: 12px;
    background-color: var(--color-background-black-main) !important;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
}

@media (max-width: 991px) {
    .navbar-light .navbar-toggler {
        background: var(--color-background-black-medium);
        border-radius: 12px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px;

        svg paht {
            fill: var(--color-text-cream)
        }
    }

    .right-actions-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;

        .nav-dropdown .round-button.right-actions-wrapper__launch-link {
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            width: auto;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: var(--color-text-yellow);
            background: var(--color-background-black-light) !important;
            border-radius: 12px;
        }

        .social-links__link {
            background: #FFCC7E;
            border-radius: 12px;

            width: 48px;
            height: 48px;

            svg path {
                fill: var(--color-background-black-main)
            }
        }
    }
}


.navbar-collapse {
    justify-content: space-between;
    .card.accordion .btn {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.005em;
        background-color: transparent;
        border-bottom: 0;
        display: inline-flex;
        color: #FFFFFF;
        padding: 0;
        align-items: center;
        justify-content: center;
        gap: 10px
    }

    .card.accordion {
        border-bottom: 0;
    }

    .card.accordion .card-body {
        padding: 15px;
        display: flex;
        gap: 24px;
        align-items: center;
        flex-direction: column;

        .nav-dropdown__item {
            font-weight: 500;
            font-size: 16px;
            line-height: 112%;
            text-decoration: none;
            text-align: center;
            color: #FFFFFF;
        }
    }

    .mobile-accordion {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}