.top-banner {
  padding: 250px 0 120px;
  background: var(--color-background);
  background-image: url('../../../assets/Landing/banner-bg.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  margin-top: -112px;
  position: relative;

  .container {
    position: relative;
    z-index: 2;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 30%;
    content: '';
    background-image: url('../../../assets/Landing/dogi-face.png');
    width: 11%;
    height: 25%;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 767px) {
      width: 178.45px;
      height: 184.43px;
      background-size: contain;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 64px;
    line-height: 112%;
    text-transform: uppercase;
    color: var(--color-text-cream);

    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      text-transform: capitalize;
      text-align: center;
    }
  }

  &__left {
    @media (max-width: 767px) {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    margin: 15px 0 30px;
    color: var(--color-text-white);
    @media (max-width: 767px) {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }
  }

  @media (max-width: 767px) {
    .round-button.large {
      width: 200px;
      height: 48px;
      border-radius: 12px;
      line-height: inherit;
      padding: 15px;
    }
  }
}

.collection-banner-card {
  position: relative;

  &:hover {
    .collection-banner-card__image {
      transform: translateY(-2px);
    }
  }

  a {
    text-decoration: none;
    display: block;
  }

  &__badge {
    color: var(--color-text-white);
    letter-spacing: 1px;
    padding: 5px 25px;
    text-transform: uppercase;
    background-color: var(--color-background-black-light);
    display: inline-flex;
    z-index: 2;
    position: absolute;
    right: 16px;
    top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    border-radius: 8px;
  }

  &__image {
    text-align: center;
    transition: all 300ms ease-in;
    border-radius: 16px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
}

.collection-metadata {
  display: flex;
  margin: 10px auto 0;
  justify-content: space-between;
  max-width: 100%;
  gap: 8px;

  @media (max-width: 767px) {
    padding: 10px;
  }

  &__item {
    padding-right: 20px;
    padding-left: 20px;
    background-color: var(--color-background-black-medium);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100px;
    justify-content: flex-start;
    padding-top: 15px;

    @media (max-width: 1400px) {
      padding: 0 10px;
    }

    @media (max-width: 1200px) {
      padding: 0 10px;
    }

    @media (max-width: 767px) {
      padding: 10px 10px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &:first-child {
      border-radius: 8px 0px 0px 8px;
    }
  }

  &__ico {
    border-radius: 50%;
    background-color: var(--color-background-black);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    img {
      max-width: 100%;
    }
  }

  &__attribute {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: var(--color-text-grey);

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__value {
    display: flex;
    gap: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 1px;
    color: #FFFFFF;

    @media (max-width: 1200px) {
      line-height: 22px;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}