@mixin blurred-dark-background($length, $position: relative) {
  @supports (backdrop-filter: blur($length)) {
    backdrop-filter: blur($length);
  }

  @supports not (backdrop-filter: blur($length)) {
    position: $position;
    background-color: rgba(43, 43, 43, 0.9);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      filter: blur($length);
      background-color: inherit;
      z-index: -1;
    }
  }
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype');
  }
}