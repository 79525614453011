.collections-tabs-section {
  padding: 80px 0;

  .collections-tabs {
    border-bottom: 0;

    .nav-link {
      font-family: 'Atrament', sans-serif;
      font-weight: 400;
      font-size: 40px;
      border: none;
      line-height: 112%;
      text-transform: uppercase;
      color: var(--color-text-black);

      @media (max-width: 767px) {
        font-size: 19px;
        padding: 0.5rem 0.3rem;
      }
    }

    .nav-link.active:after {
      background-color: var(--color-background-yellow);
    }

    .nav-item.show .nav-link, .nav-link.active {
      font-weight: 600;
      font-size: 40px;
      border: none;
      line-height: 112%;
      text-transform: uppercase;
      color: var(--color-text-yellow);

      @media (max-width: 767px) {
        font-size: 22px;
      }
    }

    .nav-link:focus, .nav-link:hover {
      border: none;
    }
  }
}