.collections-page.page {
  padding: 0;

  h1.title, .under-title {
    text-align: center;
  }

  .page-top-banner {
    background-image: url('../../assets/collections-bg.png');
    padding-top: 80px;
    background-color: rgba(88, 118, 225, 1);
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;

    @media (max-width: 767px) {
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 112%;
      }

      .under-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
}