.success-bought-modal {

  .modal-content.tile {
    min-width: 610px;
    max-width: 90%;
    background: var(--color-background-blue);
    box-shadow: var(--main-shadow);
    border-radius: 24px;
    text-align: center;
    overflow: hidden;

    @media (max-width: 767px) {
      min-width: 0;
      max-width: 95%;
    }
  }

  .close-button {
    appearance: none;
    position: absolute;
    right: 20px;
    top: 20px;
    outline: none;
    border: none;
    z-index: 10;
    background: none;
  }


  .close-button:hover svg path {
    fill: var(--color-text-yellow);
  }

  &__image {
    width: 240px;
    height: 240px;
    border-radius: 16px;
    overflow: hidden;
    margin: 20px auto;

    img {
      max-width: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--color-text-cream)
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    display: inline-flex;
    gap: 15px;
    margin: 10px 0 20px;
  }

  &__dog {
    position: absolute;
    left: 100%;
    top: 63px;
    transition: 300ms ease-in;
    animation-name: slideLeft;
    animation-duration: 600ms;
    animation-delay: 800ms;
    animation-fill-mode: forwards;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-180px);
  }
}