@media (max-width: 767px) {
    .bid-form-wrapper {
        border-top: 1px solid var(--color-separator);
    }
}

.bid-form-wrapper {
    .price__description {
        margin-bottom: 12px;
    }
}

.bid-form {
    &__buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    &__buttons .round-button {
        flex-grow: 1;
        max-width: calc(50% - 10px);

        @media (max-width: 767px) {
            padding: 5px 10px;
        }
    }

    .input-group .btn {
        background: var(--color-background-black-light);
        border: 1px solid var(--color-text-cream);
        border-radius: 8px;
        color: var(--color-text-cream);
    }
}
