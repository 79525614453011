.collection-stats {
  &__progress {
    margin-bottom: 20px;
    position: relative;

    .progress {
      background: rgba(171, 136, 0, 0.1);
      overflow: unset;
      height: 8px;
      border-radius: 8px;
      backdrop-filter: blur(20px);
      overflow: hidden;
    }

    .progress-bar {
      background: var(--color-background-yellow);
      border-radius: 8px;
    }
  }

  &__switcher {
    position: absolute;
    right: 0;
    top: -60px;

    button {
      background-color: transparent;
      padding: 0;
      margin-left: 15px;
      border: none;
      color: var(--color-text-white);
      transition: all 300ms ease-in;
      font-size: 16px;
      font-weight: 700;
      position: relative;

      &:after {
        width: 0;
        height: 2px;
        position: absolute;
        left: 0;
        top: calc(100% + 5px);
        transition: all 300ms ease-in;
        content: '';
        background-color: var(--color-text-yellow);
      }

      &:nth-child(1):after {
        right: 0;
        left: auto;
      }

      &.active:after {
        width: 100%;
      }

      &:hover {
        color: var(--color-text-yellow);
      }
    }
  }

  &__status {
    font-weight: 600;
    font-size: 40px;
    line-height: 126%;
    color: var(--color-text-cream);
    @media (max-width: 1400px) {
      margin-bottom: 44px;
    }
  }

  &__last-price span {
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;
  }

  &__last-price {
    text-align: right;
  }

  .loader {
    margin: 1rem auto -0px;
  }

  .phase-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;

    color: var(--color-text-grey);
  }

  .form-tabs {
    border-bottom: 0;
    background: #25253C;
    border-radius: 8px;
    overflow: hidden;
    padding: 4px;
    display: flex;

    .nav-link {
      padding: 10px 0;
      width: 50%;
      letter-spacing: 1px;
      color: var(--color-text-white);
      background-color: transparent;
      border: none;
      text-transform: inherit;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;

      @media (max-width: 767px) {
        overflow:hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        border: none;
      }

      &.active {
        font-weight: 700;
        color: var(--color-text-yellow);
        border: none;
        background: #33334A;
        border-radius: 8px;

        &:after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .project-stats__last-price {
    text-align: left;
    margin: 15px 0 0;
    padding-bottom: 15px;
  }
}

@media (max-width: 500px) {
  .tile.collection-stats {
    padding: 10px;

  }
}