.launch-modal {
  .modal-dialog-centered {
    justify-content: center;
  }

  .modal-content.tile {
    min-width: 610px;
    max-width: 90%;
    background: var(--color-background-blue);
    box-shadow: var(--main-shadow);
    border-radius: 24px;
    text-align: left;

    @media (max-width: 767px) {
      min-width: 0;
      max-width: 95%;
    }
  }

  .close-button {
    appearance: none;
    position: absolute;
    right: 20px;
    top: 20px;
    outline: none;
    border: none;
    z-index: 10;
    background: none;
  }

  .close-button:hover svg path {
    fill: var(--color-text-yellow);
  }

  .modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: var(--color-text-cream);
  }

  .modal-under-title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    text-align: left;
  }

}