.timer-values {
    display: flex;
    align-items: center;
    justify-content: center;
    --highlight-color: var(--color-text-yellow)
}

.timer-values__item {
    margin: 0 12px;
}

.timer-values__item .value {
    font-weight: 400;
    font-size: 40px;
    line-height: 126%;
    color: var(--highlight-color);
    text-align: center;
}

.timer-values__item.seconds .value, .timer-values__item .value .grey-text {
    color: var(--color-text-grey);
}

.timer-values__item .name {
    line-height: 144%;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    line-height: 144%;
    color: var(--color-text-grey);
}

.timer__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;

    color: var(--color-text-cream);
}


@media (max-width: 767px) {
    .tile.timer {
        display: inline-flex !important;
        margin: 0 auto;
    }

    .timer-values__item {
        margin: 0 0.5rem;
    }

    .timer-wrap {
        display: flex;
        justify-content: center;
    }
}