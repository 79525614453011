.resident-artists-section {
  padding: 80px 0;

  .artist-item {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__name {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      margin: 15px 0 5px;
      color: var(--color-text-black);
    }

    &__label {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: #8E8F96;
    }
  }
}