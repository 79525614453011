.footer {
  padding: 40px 0 40px;
  background-color: var(--color-background-black);
  color: var(--color-text-white);
  position: relative;

  &__left-column {
    padding-right: 15px;
  }

  &:after {
    position: absolute;
    content: '';
    background-image: url('../../../assets/footer-dogi-face.png');
    width: 206px;
    height: 114px;
    bottom: 0;
    right: 20%;
    z-index: 2;
    background-size: cover;

    @media (max-width: 767px) {
      right: 5%;
      width: 103px;
      height: 62px;
    }
  }

  &__logo {
    width: 240px;
    height: 65px;
    max-width: 100%;
    display: flex;

    @media (max-width: 1400px) {
      height: auto;
    }
    @media (max-width: 767px) {
      margin: 0 auto;
    }

    img {
      max-width: 100%;
    }
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: 991px) {
      justify-content: center
    }
  }

  &__copyright {
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
    text-align: center;
    letter-spacing: 1px;
    color: var(--color-text-grey);
    margin-top: 30px;

    @media (max-width: 991px) {
      text-align: center;
    }
  }
  


  &__left {
    display: flex;
    align-items: flex-start;

    @media (max-width: 991px) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .social-links {
    justify-content: flex-start;

    @media (max-width: 767px) {
      justify-content: center;
    }

    .social-links__link:hover {
      background: transparent;

      svg path {
        fill: var(--color-background-yellow)
      }
    }
  }
}

.footer-menu {
  margin-left: 0px;
  padding-left: 0;
  margin-top: 16px;

  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 15px;


  @media (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 991px) {
    margin-left: 0;
    margin-top: 25px;
  }

  &__item {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    text-transform: capitalize;

    a {
      text-decoration: none;
    }
  }
}

.footer-info-block {
  margin-bottom: 35px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
    letter-spacing: 1px;
    color: var(--color-text-grey);

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}