.team-card {
    text-align: center;
    padding: 16px 0;
}

.team-card__image-box {
    position: relative;
    width: 160px;
    margin: 0 auto 16px;
}

.team-card__image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    filter: grayscale(1);
    transition: .15s linear;
}

.team-card:hover .team-card__image {
    filter: grayscale(0);
}

.team-card__logo {
    position: absolute;
    right: -5px;
    bottom: 10px;
}

.team-card__name {
    font-size: 24px;
}

.team-card__bio {
    margin-bottom: 0;
    font-size: 16px;
}

@media (max-width: 767px) {
    .team-card__image {
        width: 120px;
        height: 120px;
    }

    .team-card__name {
        font-size: 16px;
    }

    .team-card__bio {
        font-size: 12px;
    }
}
