.phase-list {
  overflow: hidden;

  .phase-row {
    display: flex;
    padding: 20px 15px;

    &__time {
      font-size: 12px;
    }

    &--head {
      background: transparent;
      padding: 10px 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: var(--color-text-grey);
      opacity: 0.7;
    }

    &__col {
      width: 100%;
      max-width: 33.33%;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: var(--color-text-white);

      .t-small {
        font-size: 14px;
      }

      @media (max-width: 767px) {
        font-size: 8px;
      }
    }

    &__col:nth-child(1) {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;

      @media (max-width: 767px) {
        font-size: 12px;
      }

      @media (max-width: 500px) {
        font-size: 8px;
      }
    }

    &--active {
      background: rgba(255, 204, 126, 0.16);
      border-radius: 8px;

      .phase-row__col {
        color: var(--color-text-white);
      }

      .phase-row__col:nth-child(1) {
        color: var(--color-text-yellow)
      }

      .phase-row__col:nth-child(2) {
        font-weight: 700;
        color: var(--color-text-yellow)
      }
    }

    &--link {
      cursor: pointer;
      transition: all 300ms ease-in;

      &:hover {
        opacity: 0.8;
        transform: translateY(2px);
      }
    }
  }
}

.phase-item {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &--active {
    background: rgba(255, 204, 126, 0.16);

    .phase-item__name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: var(--color-text-yellow);
    }

    .phase-item__row:nth-child(2) .phase-item__value {
      color: var(--color-text-cream);
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  &__attr-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: var(--color-text-grey);
    opacity: 0.7;
  }

  &__value {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
  }
}