.card.accordion span {
    display: block;
}

.card.accordion {
    font-size: 18px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #33334A;
    margin-bottom: 1rem;
    text-align: left;
}

.card.accordion .collapse {
    padding-top: 0;
    line-height: 150%;
}

.card.accordion .card-body {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;

    color: var(--color-text-white);
}

.card.accordion .card-header {
    border: 0;
    padding: 0;
    background-color: transparent;
}

.card.accordion .btn {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100% !important;
    font-weight: 600;
    text-align: left;
    font-size: 24px;
    line-height: 32px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: var(--color-text-white);
    padding: 32px 36px;
}

.card.accordion .btn span {
    transition: 0.3s linear;
}

.card.accordion .btn.active {
    color: var(--color-text-yellow);
}

.card.accordion svg path {
    fill: var(--color-text-white);
}

.card.accordion .card-body {
    padding: 0 8rem 2.25rem 2.25rem;
}

.card.accordion .btn.active svg path {
    fill: var(--color-background-yellow);
}
