.gas-tracker {
  margin-top: 10px;
  max-width: 350px;

  @media (max-width: 767px) {
    margin: 10px auto 0;
  }

  &__title {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--color-text-grey);
    margin-bottom: 10px;
    @media (max-width: 991px) {
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
    @media (max-width: 991px) {
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }

  .gas-tracker-item {
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: var(--color-background-black-medium);
    border-radius: 8px;
    flex-grow: 1;

    &__label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: var(--color-text-grey);
    }

    &__value {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.5px;
      color: #FFFFFF;


      @media (max-width: 1400px) {
        font-size: 10px;
      }
    }
  }
}