.share-with {
  &__list {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 0;
    margin: 0;
    list-style: none;

    a {
      border: 1px solid #FFEACB;
      border-radius: 16px;
      background: var(--color-background-black-light);
      backdrop-filter: blur(40px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;

      svg {
        width: 27px;
        height: 27px;
      }

      svg path {
        fill: var(--color-text-cream);
        transition: all 300ms ease-in;
      }

      &:hover svg path {
        fill: var(--color-background-yellow)
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #8E8F96;
    margin-bottom: 10px;
  }
}