.market-page {
  padding: 80px 0;

  .load-more {
    padding: 14px 20px;
    width: 328px;
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(40px);
    border-radius: 50px;
    color: var(--color-text-white);
  }
}