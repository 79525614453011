.searchbar {
  max-width: 286px;
  .input-group .form-control {
    text-align: left;
  }
  .input-group-prepend {
    margin-right: 0;

    svg * {
      fill: var(--color-text-black) !important;
    }
  }
}