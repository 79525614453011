.collectors {
    .collectors-tabs {
        border-bottom: 0;

        .nav-link {
            font-family: 'Atrament', sans-serif;
            font-weight: 400;
            font-size: 40px;
            border: none;
            line-height: 112%;
            text-transform: uppercase;
            color: var(--color-text-black);

            @media (max-width: 767px) {
                font-size: 19px;
                padding: 0.5rem 0.3rem;
            }
        }

        .nav-link.active:after {
            background-color: var(--color-background-yellow);
        }

        .nav-item.show .nav-link, .nav-link.active {
            font-weight: 600;
            font-size: 40px;
            border: none;
            line-height: 112%;
            text-transform: uppercase;
            color: var(--color-text-yellow);

            @media (max-width: 767px) {
                font-size: 22px;
            }
        }

        .nav-link:focus, .nav-link:hover {
            border: none;
        }
    }
}
.collectors-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 16px;
}

.collectors-table__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    font-size: 16px;
    letter-spacing: 0.5px;
    background: #F8F8F8;
    border-radius: 4px;
    margin-bottom: 8px;
    font-weight: 600;
    line-height: 150%;
    color: #050000;
}

.collectors-table__head .collectors-table__row {
    padding: 15px 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #6B6B6B;
    background-color: transparent;
}

.collectors-table__column {
    flex-grow: 1;
    width: 33.33%;
}

.collector-item__wallet {
    display: flex;
    gap: 12px;
    align-items: center;
    color: var(--color-text-yellow);
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.collector-item__price {
    font-weight: 600;
    display: flex;
    gap: 12px;
    font-size: 24px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.5px;
    color: #050000;
}

.collector-item__price span {
    color: #2E2B2C;
    font-weight: 400;
    font-size: 18px;
}

@media (max-width: 767px) {
    .collectors-table__row {
        flex-direction: column;
        gap: 16px;
        padding: 16px;
    }

    .collector-item__mobile-desc {
        color: var(--color-text-white-transparantize);
    }

    .collectors-table__column {
        width: 100%;
    }
}