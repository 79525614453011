.nft-card.tile {
  --card-border: 1px;
  padding: 0;
  background: var(--color-background-black-main);
  box-shadow: var(--main-shadow);
  border-radius: 24px;
  border: none;
  position: relative;
  display: block;
  background-clip: padding-box;
  color: inherit;
  backdrop-filter: none;
  text-decoration: none;
  overflow: hidden;
}

.nft-card {
  @media (max-width: 767px) {
    max-width: 100%;
    margin: auto;
    width: 320px;
  }

  &__img {
    height: 392px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
    background-color: var(--color-background-black-medium);

    img {
      max-width: 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: var(--color-text-white);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: var(--color-text-grey);
  }

  &__body {
    padding: 24px;
  }

  &__cost {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-yellow)
  }

  &__bot {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--color-text-black);
    padding-top: 24px;
    position: relative;

    &:before {
      content: '';
      border-top: 1px solid #8E8F96;
      opacity: 0.5;
      height: 1px;
      width: 100%;
      display: block;
      margin-bottom: 24px;
    }
  }
}