.timer-wrap {
    position: relative;
}

.timer-blur {
    left: 0;
    top: 0;
    z-index: 80;
    background-size: 100%;
}

.timer {
    &.tile {
        padding: 40px;
        background: #1E1E2F;
        border-radius: 24px;
    }

    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        text-align: left;
        text-transform: capitalize;
    }
}
